import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = ["input", "modal", "form", "tags"]

  connect() {
    useClickOutside(this, { element: this.formTarget })
  }

  showModal() {
    this.modalTarget.hidden = false
  }

  closeModal() {
    this.modalTarget.hidden = true
  }

  clickOutside(event) {
    if (!this.modalTarget.hidden) {
      event.preventDefault()
      this.closeModal()
    }
  }

  fillInput(event) {
    const str = event.currentTarget.dataset.prefill;
    this.inputTarget.value = str;
    this.inputTarget.focus();
    this.closeModal();
  }

  addTag(event) {
    const str = event.currentTarget.dataset.tagvalue;

    if (this.tagsTarget.value.includes(str)) {
      this.closeModal()
    } else {
      this.tagsTarget.value = this.tagsTarget.value.concat(' ', str);
      this.formTarget.querySelector("button").click();
    }
  }
}
